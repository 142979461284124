export const name = "en_US";
export const maintainer = "real186 <i@186526.xyz>";
export const context = {
  userName: "User Name",
  uuid: "UUID",
  changePassword: "Change Password",
  password: "Password",
  forgetPassword: "Forget Password",
  signIn: "Sign In",
  changeSkin: "Change Skin",
  register: "Sign Up",
  notHaveAccount: "Don't have an account"
};
